import isBrowser from "../utils/isBrowser"
import Cookies from "js-cookie"

interface ICustomer {
  isLoggedIn?: boolean
  is_logged_in?: `true` | `false`
  id?: string
  email?: string
}

const useCustomer = () => {
  if (!isBrowser) {
    return null
  }

  const cookies = Cookies.get() || {}

  const customer: ICustomer = {}

  Object.keys(cookies)?.forEach?.(key => {
    if (key.startsWith(`customer_`)) {
      if (key === `customer_is_logged_in` && cookies[key] === `true`) {
        customer.isLoggedIn = true
      }

      customer[key.replace(`customer_`, ``)] = cookies[key]
    }
  })

  if (process.env.NODE_ENV === `development`) {
    return {
      isLoggedIn: true,
      is_logged_in: "true", // keeping for backwards compatibility
      id: "5741250216075",
      email: "caleb@bare.ca",
    }
  }

  return customer
}

export default useCustomer
