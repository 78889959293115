import React, { useState } from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
import MinusIcon from "@heroicons/react/outline/MinusIcon"
import AddIcon from "@heroicons/react/outline/PlusIcon"

import { useShopify } from "../../shopify"
import { useDispatch } from "react-redux"
import { setCart } from "../../app/features/cartSlice"
import formatCurrencyString from "../../utils/formatCurrencyString"

const LineItem = props => {
  const { id, title, quantity, variant, products, calculationValid } = props

  const dispatch = useDispatch()
  const { removeLineItem, updateLineItem } = useShopify()

  const [loading, setLoading] = useState(false)

  const product = products?.find(
    item => item?.shopifyId === variant?.product?.id
  )

  const handleDecrementItem = () => {
    setLoading(true)

    if (quantity > 1) {
      updateLineItem({
        lineItemId: id,
        quantity: quantity - 1,
        onCompleted: () => setLoading(false),
        onError: () => setLoading(false),
      })
    } else {
      updateLineItem({
        lineItemId: id,
        quantity: 1,
        onCompleted: () => setLoading(false),
        onError: () => setLoading(false),
      })
    }
  }

  const handleIncrementItem = () => {
    setLoading(true)

    updateLineItem({
      lineItemId: id,
      quantity: quantity + 1,
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    })
  }

  const handleRemoveItem = () => {
    // setIsDeleting(true)
    setLoading(true)

    removeLineItem({
      notification: `Removed ${title} from cart`,
      lineItemId: id,
      onCompleted: () => setLoading(false),
      onError: () => setLoading(false),
    })
  }

  return (
    <div className="flex flex-row items-center py-[15px] ">
      {variant?.image?.src && (
        <Link
          to={product?.wpProduct?.uri}
          onClick={() => dispatch(setCart(false))}
        >
          <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
            <img
              src={variant?.image?.src}
              alt={variant?.image?.altText || ""}
              className="h-full w-full object-cover object-center"
            />
          </div>
        </Link>
      )}

      <div className="ml-4 flex flex-1 flex-col ">
        <div className="mb-[15px]">
          <div className="flex flex-row items-center justify-between">
            <Link
              to={product?.wpProduct?.uri}
              onClick={() => dispatch(setCart(false))}
            >
              <span className="text-[16px] font-semibold ">{title}</span>
            </Link>
            {variant?.price?.amount && (
              <div className="font-semibold">
                {formatCurrencyString({ amount: variant?.price?.amount })}
              </div>
            )}
          </div>
          {variant?.selectedOptions?.length >= 1 && (
            <div>
              {variant.selectedOptions.map(item => {
                if (item.value === "Default Title") {
                  return null
                }

                return (
                  <div className="text-[14px] text-mediumGrey">
                    <span>{item.name}: </span>
                    <span>{item.value}</span>
                  </div>
                )
              })}
            </div>
          )}
        </div>
        <div>
          <div className=" flex flex-row items-center justify-between">
            <Amount>
              <div className="flex flex-row items-center">
                <MinusButton disabled={!!loading} onClick={handleDecrementItem}>
                  <MinusIcon className="w-[15px] " />
                </MinusButton>
                <div className="mx-[2px] flex flex-row items-center text-[14px] text-mediumGrey">
                  <p className="ml-[13px] ">Qty:</p>
                  <input
                    disabled
                    value={quantity}
                    className="w-[30px] border-none px-0 text-center"
                    type="number"
                    onChange={e => {
                      if (!calculationValid) {
                        setLoading(true)

                        if (parseInt(e.target.value) < 1) {
                          updateLineItem({
                            lineItemId: id,
                            quantity: 1,
                            onCompleted: () => setLoading(false),
                            onError: () => setLoading(false),
                          })
                        } else {
                          updateLineItem({
                            lineItemId: id,
                            quantity: e.target.value,
                            onCompleted: () => setLoading(false),
                            onError: () => setLoading(false),
                          })
                        }
                      }
                    }}
                  />
                </div>

                <button disabled={!!loading} onClick={handleIncrementItem}>
                  <AddIcon className="w-[15px]" />
                </button>
              </div>
            </Amount>
            <button
              className="flex cursor-pointer flex-row items-center text-[14px] font-semibold text-darkBlue"
              onClick={handleRemoveItem}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LineItem

const Amount = styled.div`
  flex-shrink: 0;
  width: 150px;
  @media (min-width: 640px) {
    width: 150px;
    margin-right: 16px;
  }
`

const MinusButton = styled.button`
  color: span;
  cursor: pointer;

  &:hover {
    color: primary;
  }
`
// const DeleteButton = ({ loading, ...rest }) => {
//   return (
//     <button
//       sx={{
//         color: "span",
//         "&:hover": {
//           color: "primary",
//         },
//         flexShrink: 0,
//         width: ["56px", "56px"],
//         height: ["56px", "56px"],
//         position: "relative",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         cursor: "pointer",
//         ".delete-icon": {
//           transition: "opacity 0.3s ease-in-out",
//           opacity: loading ? 0 : 1,
//           width: ["24px", "24px"],
//           height: ["24px", "24px"],
//         },
//         ".delete-loading-spinner": {
//           position: "absolute",
//           top: ["18px", "18px"],
//           left: ["18px", "18px"],
//           transition: "opacity 0.2s ease-in-out",
//           opacity: loading ? 1 : 0,
//         },
//       }}
//       {...rest}
//     >
//       <TrashCan className="delete-icon" />

//       {/* <div className="delete-loading-spinner" size={20}>
//         loading...
//       </div> */}
//     </button>
//   )
// }
