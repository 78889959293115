import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Navigation from "./Navigation"

// const NewsLetter = lazy(() => import("./newsletter/Newsletter"))
import NewsLetter from "./newsletter/Newsletter"
import useThemeOptions from "../../../hooks/useThemeOptions"

interface IFooter {
  commercialPage: any
  simple: any
  themeOptions: any
}

const Footer = ({ commercialPage, simple }: IFooter) => {
  const themeOptions = useThemeOptions()

  return (
    <>
      {!simple && (
        <NewsLetter
          headline={
            commercialPage
              ? themeOptions?.Options?.commercialFooter?.newsletter?.headline
              : themeOptions?.Options?.footer?.newsletter?.headline
          }
          description={themeOptions?.Options?.footer?.newsletter?.description}
          backgroundImage={
            commercialPage
              ? themeOptions?.Options?.commercialFooter?.newsletter
                  ?.backgroundImage
              : themeOptions?.Options?.footer?.newsletter?.backgroundImage
          }
        />
      )}
      <footer className="bg-darkBlue pt-5 pb-[57px] text-white">
        <Navigation commercialPage={commercialPage} simple />
        <div
          className={
            "mx-auto flex max-w-7xl flex-col items-start justify-between px-[15px] sm:flex-row sm:items-end sm:px-6 lg:px-8"
          }
        >
          <div>
            {themeOptions?.Options?.footer?.socialTitle && (
              <h5 className="uppercase">
                {themeOptions?.Options?.footer?.socialTitle}
              </h5>
            )}

            <div className="mt-3 flex space-x-[18px]">
              {themeOptions?.Options?.siteOptions?.socialMedia.map(
                ({ image, url }, index) => {
                  const gatsbyImage = image && getImage(image?.gatsbyImage)
                  if (!gatsbyImage) {
                    return null
                  }

                  return simple ? (
                    <a
                      key={index}
                      href={url}
                      target="_parent"
                      aria-label={image?.altText || "social-media-icon"}
                    >
                      <GatsbyImage
                        image={gatsbyImage}
                        alt={image?.altText || ""}
                      />
                    </a>
                  ) : (
                    <a key={index} href={url} target="_blank" rel="noreferrer">
                      <GatsbyImage
                        image={gatsbyImage}
                        alt={image?.altText || ""}
                        aria-label={image?.altText || "social-media-icon"}
                      />
                    </a>
                  )
                }
              )}
            </div>
          </div>
          {!commercialPage
            ? themeOptions?.Options?.footer?.copyrightText && (
                <p className="mt-20 hidden text-body1 font-normal md:px-[15px] lg:block">
                  {themeOptions?.Options?.footer?.copyrightText}
                </p>
              )
            : themeOptions?.Options?.commercialFooter?.copyrightText && (
                <p className="mt-20 hidden text-body1 font-normal md:px-[15px] lg:block">
                  {themeOptions?.Options?.commercialFooter?.copyrightText}
                </p>
              )}
          {!commercialPage && (
            <div className="mt-10 sm:mt-0">
              {themeOptions?.Options?.footer?.paymentTitle && (
                <h5 className="uppercase">
                  {themeOptions?.Options?.footer?.paymentTitle}
                </h5>
              )}
              <div className="mt-3 flex space-x-[18px]">
                {themeOptions?.Options?.siteOptions?.paymentIcons.map(
                  ({ image }, index) => {
                    const gatsbyImage = image && getImage(image?.gatsbyImage)
                    if (!gatsbyImage) {
                      return null
                    }
                    return (
                      <div key={index}>
                        <GatsbyImage
                          image={gatsbyImage}
                          alt={image?.altText || ""}
                        />
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          )}
          {commercialPage && <div className="hidden w-[21%] lg:block"></div>}
        </div>
        {!commercialPage
          ? themeOptions?.Options?.footer?.copyrightText && (
              <p className="mt-20 block px-[15px] text-body1 font-normal lg:hidden">
                {themeOptions?.Options?.footer?.copyrightText}
              </p>
            )
          : themeOptions?.Options?.commercialFooter?.copyrightText && (
              <p className="mt-20 block px-[15px] text-body1 font-normal lg:hidden">
                {themeOptions?.Options?.commercialFooter?.copyrightText}
              </p>
            )}
      </footer>
      {commercialPage && (
        <Link to="/">
          <h5 className="flex h-[48px] w-full items-center justify-center bg-mediumBlue text-[#fff]">
            SWITCH TO HOME GYMS
          </h5>
        </Link>
      )}
    </>
  )
}

export default Footer
