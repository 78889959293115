import React, { useState, useEffect } from "react"
import Button from "./button/Button"
import Wysiwyg from "./Wysiwyg"
import isBrowser from "../utils/isBrowser"

const CookieBar = ({ content }) => {
  const [showBanner, setShowBanner] = useState(false)

  const handleBanner = () => {
    window.localStorage.setItem("SHOW_BANNER", "false")
    setShowBanner(false)
  }
  useEffect(() => {
    setTimeout(() => {
      const data = window.localStorage.getItem("SHOW_BANNER")

      if (data !== null) setShowBanner(JSON.parse(data))
    }, 9999)
  }, [])
  useEffect(() => {
    setTimeout(() => {
      if (
        showBanner === false &&
        isBrowser &&
        window.localStorage.getItem("SHOW_BANNER") === null
      ) {
        window.localStorage.setItem("SHOW_BANNER", "true")
      }
    }, 9000)
  }, [showBanner])

  return (
    <>
      {showBanner &&
        isBrowser &&
        window.localStorage.getItem("SHOW_BANNER") === "true" && (
          <div className="fixed left-0 right-0 bottom-0 z-[9999999999999] flex flex-row flex-wrap items-center justify-evenly bg-darkBlue/[.8] py-5 text-center text-body1 lg:pt-[10px] lg:pb-[10px]">
            <Wysiwyg content={content} />

            <Button
              onClick={() => handleBanner(false)}
              className="relative my-[15px] lg:my-0"
            >
              Accept
            </Button>
          </div>
        )}
    </>
  )
}

export default CookieBar
