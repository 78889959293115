module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/layout/Layout.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@jambaree/gatsby-theme-wordpress/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://fitnesstowncms.wpengine.com/graphql","verbose":true,"html":{"useGatsbyImage":false,"generateAvifImages":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"placeholderType":"dominantColor"},"type":{"MediaItem":{"createFileNodes":false,"excludeFieldNames":["seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","srcSet","parent","children"],"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"timeout":120000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"catchLinks":true},
    },{
      plugin: require('../node_modules/@jambaree/gatsby-theme-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://fitnesstowncms.wpengine.com","gatsby-theme-headless-wordpress":{"options":{"templatesPath":"./src/templates","excludedNodeTypes":["MediaItem","CompatibleApp","BuyersGuide","Brand","Product"]}},"gatsby-source-wordpress":{"options":{"verbose":true,"url":"https://fitnesstowncms.wpengine.com/graphql","html":{"useGatsbyImage":false,"generateAvifImages":false},"type":{"MediaItem":{"createFileNodes":false,"excludeFieldNames":["seo","ancestors","author","template","lastEditedBy","authorDatabaseId","authorId","contentTypeName","dateGmt","desiredSlug","enclosure","isContentNode","isTermNode","modified","modifiedGmt","parentDatabaseId","parentId","srcSet","parent","children"]}},"schema":{"timeout":120000}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fitness Town","short_name":"Fitness Town","start_url":"/","background_color":"#FFF","theme_color":"#07497D","display":"minimal-ui","icon":"src/images/FTIC.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"449bad33bb30cccd770aecc85e07ad33"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
