exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-search-[term]-tsx": () => import("./../../../src/pages/search/[term].tsx" /* webpackChunkName: "component---src-pages-search-[term]-tsx" */),
  "component---src-pages-simple-footer-js": () => import("./../../../src/pages/simple-footer.js" /* webpackChunkName: "component---src-pages-simple-footer-js" */),
  "component---src-pages-simple-header-js": () => import("./../../../src/pages/simple-header.js" /* webpackChunkName: "component---src-pages-simple-header-js" */),
  "component---src-pages-simple-newsletter-js": () => import("./../../../src/pages/simple-newsletter.js" /* webpackChunkName: "component---src-pages-simple-newsletter-js" */),
  "component---src-pages-wishlist-js": () => import("./../../../src/pages/wishlist.js" /* webpackChunkName: "component---src-pages-wishlist-js" */),
  "component---src-templates-archive-case-study-tsx": () => import("./../../../src/templates/archive/caseStudy.tsx" /* webpackChunkName: "component---src-templates-archive-case-study-tsx" */),
  "component---src-templates-archive-post-tsx": () => import("./../../../src/templates/archive/post.tsx" /* webpackChunkName: "component---src-templates-archive-post-tsx" */),
  "component---src-templates-case-study-default-tsx": () => import("./../../../src/templates/caseStudy/default.tsx" /* webpackChunkName: "component---src-templates-case-study-default-tsx" */),
  "component---src-templates-collection-default-tsx": () => import("./../../../src/templates/collection/default.tsx" /* webpackChunkName: "component---src-templates-collection-default-tsx" */),
  "component---src-templates-commercial-page-default-tsx": () => import("./../../../src/templates/commercialPage/default.tsx" /* webpackChunkName: "component---src-templates-commercial-page-default-tsx" */),
  "component---src-templates-custom-product-type-tsx": () => import("./../../../src/templates/custom/productType.tsx" /* webpackChunkName: "component---src-templates-custom-product-type-tsx" */),
  "component---src-templates-page-default-tsx": () => import("./../../../src/templates/page/default.tsx" /* webpackChunkName: "component---src-templates-page-default-tsx" */),
  "component---src-templates-post-default-tsx": () => import("./../../../src/templates/post/default.tsx" /* webpackChunkName: "component---src-templates-post-default-tsx" */),
  "component---src-templates-product-default-tsx": () => import("./../../../src/templates/product/default.tsx" /* webpackChunkName: "component---src-templates-product-default-tsx" */),
  "component---src-templates-taxonomy-category-tsx": () => import("./../../../src/templates/taxonomy/category.tsx" /* webpackChunkName: "component---src-templates-taxonomy-category-tsx" */),
  "component---src-templates-taxonomy-commercial-category-tsx": () => import("./../../../src/templates/taxonomy/commercialCategory.tsx" /* webpackChunkName: "component---src-templates-taxonomy-commercial-category-tsx" */)
}

