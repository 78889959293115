import { graphql, useStaticQuery } from "gatsby"

const useThemeOptions = () => {
  const {
    wp: {
      themeOptions: { Options },
    },
  } = useStaticQuery(graphql`
    query HeaderOptions {
      wp {
        themeOptions {
          Options {
            cookieBanner
            blogArchivePageDescription
            commercialArchiveDescription
            globalExpertGuidance {
              heading
              description
              fieldGroupName
              headingImage {
                altText
                gatsbyImage(
                  quality: 90
                  width: 58
                  height: 58
                  formats: [AUTO, WEBP]
                )
              }
              buttonGroup {
                link {
                  title
                  url
                }
              }
            }
            globalDetailedSpecifications {
              detailedSpecificationsHeading
            }
            globalSocialBlock {
              description
              heading
              socialIcons {
                icon {
                  gatsbyImage(
                    height: 34
                    width: 34
                    layout: FIXED
                    formats: [AUTO, WEBP]
                  )
                }
                url
              }
            }
            globalRecommendedProducts {
              heading
            }
            globalCompatibleApps {
              compatibleAppsHeading
              compatibleAppsDescription
            }
            globalProductOverview {
              productOverviewHeading
            }
            globalFeatures {
              featuresHeading
            }

            productSettings {
              anchorLinks {
                link {
                  title
                  url
                }
              }
              paybrightFinancingLink {
                target
                title
                url
              }
            }
            siteOptions {
              paymentIcons {
                image {
                  altText
                  gatsbyImage(width: 62, height: 39, formats: [AUTO, WEBP])
                }
              }
              socialMedia {
                image {
                  altText
                  gatsbyImage(width: 36, height: 36, formats: [AUTO, WEBP])
                }
                url
              }
            }
            commercialFooter {
              copyrightText
              fieldGroupName
              socialTitle
              newsletter {
                description
                fieldGroupName
                headline
                backgroundImage {
                  altText
                  gatsbyImage(
                    quality: 70
                    width: 1366
                    height: 536
                    formats: [AUTO, WEBP]
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            commercialHeader {
              logo {
                gatsbyImage(
                  width: 202
                  height: 49
                  quality: 70
                  formats: [AUTO, WEBP]
                )
              }
              logoLink
              requestAQuote {
                title
                url
              }
              promobar {
                active
                link {
                  url
                  title
                  target
                }
                stickyBar
              }
            }
            footer {
              copyrightText
              fieldGroupName
              paymentTitle
              socialTitle
              newsletter {
                description
                fieldGroupName
                headline
                backgroundImage {
                  altText
                  gatsbyImage(
                    quality: 70
                    width: 1366
                    height: 536
                    formats: [AUTO, WEBP]
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            header {
              logo {
                altText
                gatsbyImage(
                  width: 202
                  height: 49
                  quality: 85
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
              promobar {
                active
                link {
                  url
                  title
                  target
                }
                stickyBar
              }
            }
          }
        }
      }
    }
  `)

  return { Options, ...Options } // left Options for backwards compatibility
}

export default useThemeOptions
