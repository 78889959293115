import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

interface IDropdownNavProps {
  menuItems: {
    label: string
    childItems: {
      nodes: {
        id: string
        url: string
        label: string
        description: string
        img: string
        childItems: {
          nodes: {
            id: string
            url: string
            label: string
            description: string
            img: string
          }[]
        }
      }[]
    }
  }[]
  pageUrl: string
  hoveredMenu: string
  hoveredCollection: string
  setHoveredMenu: any
  setHoveredCollection: any
  resetMenu: any
}

const DropdownNav: React.FC<IDropdownNavProps> = ({
  hoveredMenu,
  menuItems,
  resetMenu,
  pageUrl,
}) => {
  const [hoveredChilditem, setHoveredChilditem] = useState("default")
  // find the menuItems with the label that matches the hoveredMenu
  const hoveredMenuItem = menuItems.find(item => item.label === hoveredMenu)
  // check if the hoveredMenuItem has childItems or not
  const hasChildItems =
    hoveredMenuItem && hoveredMenuItem?.childItems?.nodes?.length > 0
      ? true
      : false

  return (
    hoveredMenu !== "default" &&
    hasChildItems && (
      <div className="absolute left-0 right-0  z-[999999] mx-auto flex max-w-[1304px] flex-row rounded bg-white pt-[56px] pr-[20px] pb-[25px] text-darkGrey shadow-menu">
        <div className="mr-[30px]">
          {menuItems.map(
            ({ label, childItems }, index) =>
              label === hoveredMenu && (
                <div key={index} className="mt-1 flex flex-row">
                  {childItems?.nodes &&
                    childItems?.nodes.map((item, childIndex) => (
                      <div
                        key={childIndex + Math.floor(Math.random() * 99999999)}
                        className="mx-[40px] flex w-fit flex-col"
                      >
                        <Link
                          to={item.url}
                          title={item.label}
                          onClick={resetMenu}
                        >
                          <h6 className="w-fit whitespace-nowrap capitalize">
                            {item.label}
                          </h6>
                          <div className="mt-[10px] h-[2px] w-full  bg-lightGrey"></div>
                        </Link>

                        {item &&
                          item?.childItems?.nodes?.map(
                            (itemChildItems, itemChildIndex) => {
                              return (
                                <div
                                  className="flex flex-row justify-between"
                                  key={
                                    itemChildIndex +
                                    Math.floor(Math.random() * 99999999)
                                  }
                                >
                                  <Link
                                    to={itemChildItems.url}
                                    title={itemChildItems.label}
                                    onClick={resetMenu}
                                    onMouseEnter={e =>
                                      setHoveredChilditem(itemChildItems.label)
                                    }
                                    onMouseLeave={e =>
                                      setHoveredChilditem("default")
                                    }
                                  >
                                    <div
                                      className={
                                        pageUrl !== itemChildItems.url
                                          ? "whitespace-nowrap text-dropdownNav font-normal normal-case hover:text-darkBlue focus:font-bold"
                                          : "whitespace-nowrap text-dropdownNav font-bold normal-case text-darkBlue"
                                      }
                                    >
                                      {itemChildItems.label}
                                    </div>
                                  </Link>
                                </div>
                              )
                            }
                          )}
                      </div>
                    ))}
                </div>
              )
          )}
        </div>

        <div className="ml-[35px] flex flex-row lg:ml-0">
          {menuItems.map(
            ({ label, childItems }, index) =>
              label === hoveredMenu && (
                <div key={index} className="mt-1">
                  {childItems?.nodes &&
                    childItems?.nodes.map(
                      (item, childIndex) =>
                        item &&
                        item?.childItems?.nodes?.map(itemChildItems => {
                          const childMenuItemImage =
                            itemChildItems?.menuDetails?.image &&
                            getImage(
                              itemChildItems?.menuDetails?.image?.gatsbyImage
                            )

                          return (
                            <div
                              className=" flex w-full flex-col justify-between  lg:flex-row "
                              key={
                                childIndex +
                                Math.floor(Math.random() * 99999999)
                              }
                            >
                              {hoveredChilditem === itemChildItems.label && (
                                <div className=" w-fit">
                                  <h6 className="w-fit capitalize">
                                    {itemChildItems.label}
                                    <div className="mt-[10px] h-[2px] w-full bg-lightGrey"></div>
                                  </h6>
                                  <div className="mt-[7px] max-w-[277px] text-left text-body2 font-normal normal-case">
                                    {itemChildItems?.menuDetails?.description}
                                  </div>
                                </div>
                              )}

                              {hoveredChilditem === itemChildItems.label &&
                                childMenuItemImage && (
                                  <GatsbyImage
                                    alt={
                                      itemChildItems?.menuDetails?.image
                                        ?.altText || ""
                                    }
                                    className="ml-0 lg:ml-[50px]"
                                    image={childMenuItemImage}
                                  />
                                )}
                            </div>
                          )
                        })
                    )}
                </div>
              )
          )}
          {menuItems.map(({ label, childItems }, index) => {
            const mainMenuItemImage =
              menuItems[index]?.menuDetails?.image &&
              getImage(menuItems[index]?.menuDetails?.image?.gatsbyImage)
            return (
              <div
                key={index}
                className="mt-1 flex flex-col justify-between lg:flex-row"
              >
                {hoveredChilditem === "default" && hoveredMenu === label && (
                  <div className="w-fit">
                    <h6 className="w-fit capitalize">
                      {menuItems[index]?.label}
                      <div className="mt-[10px] h-[2px] w-full bg-lightGrey"></div>
                    </h6>
                    <div className="mt-[7px] max-w-[277px] text-left text-body2 font-normal normal-case">
                      {menuItems[index]?.menuDetails?.description}
                    </div>
                  </div>
                )}

                {hoveredChilditem === "default" &&
                  hoveredMenu === label &&
                  mainMenuItemImage && (
                    <GatsbyImage
                      alt={menuItems[index]?.menuDetails?.image?.altText || ""}
                      image={mainMenuItemImage}
                      className="ml-0 lg:ml-[50px]"
                    />
                  )}
              </div>
            )
          })}
        </div>
      </div>
    )
  )
}
export default DropdownNav
