/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react"

import { useSelector, useDispatch } from "react-redux"
import { setCart } from "../../app/features/cartSlice"

import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { useShopify } from "../../shopify"
import LineItem from "./LineItem"
import formatCurrencyString from "../../utils/formatCurrencyString"
import Button from "../../components/button/Button"
import useAllProducts from "../../hooks/useAllProducts"

export default function CartMenu({ location }) {
  const cartState = useSelector(state => state.cart.active)
  const dispatch = useDispatch()

  const products = useAllProducts()
  const { checkout } = useShopify()

  const handleOnClose = () => {
    document.body.style.overflow = "visible"
    document.body.style.paddingRight = "0px"
    dispatch(setCart(false))
  }

  const isTesting = location?.search?.includes("test")
  const isCheckoutDisabled =
    !isTesting &&
    (process.env.GATSBY_CHECKOUT_DISABLED ||
      !checkout ||
      checkout?.lineItems?.length === 0)

  return (
    <Transition.Root show={cartState} as={Fragment}>
      <Dialog as="div" onClose={() => handleOnClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <button
            className="fixed inset-0 z-[599] h-full w-full cursor-pointer bg-gray-500 bg-opacity-75 transition-opacity"
            onClick={handleOnClose}
          >
            {" "}
          </button>
        </Transition.Child>

        <div className="fixed relative inset-0 z-[600] overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto z-50 w-screen max-w-md">
                  <div className="flex h-full flex-col bg-white shadow-xl">
                    <div className="flex-1 overflow-y-auto py-6 px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-darkBlue ">
                          {" "}
                          Shopping cart{" "}
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="-m-2 p-2 text-gray-400 hover:text-gray-500"
                            onClick={handleOnClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>

                      <div className="mt-8">
                        <div className="flow-root">
                          {(!checkout || checkout?.lineItems?.length === 0) && (
                            <div>
                              <span>Your cart is currently empty!</span>
                            </div>
                          )}
                          <div className="-my-6 divide-y divide-gray-200">
                            {checkout?.lineItems?.map(lineItem => (
                              <LineItem
                                key={lineItem.id}
                                {...lineItem}
                                products={products}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                      <div className="flex justify-between font-sans font-semibold text-gray-900">
                        <p>Subtotal</p>
                        {formatCurrencyString({
                          amount: checkout?.subtotalPriceV2?.amount,
                        })}
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        Shipping and taxes calculated at checkout.
                      </p>

                      <div className="mx-[15px] mt-6">
                        {isCheckoutDisabled ? (
                          <>
                            {process.env.GATSBY_CHECKOUT_DISABLED && (
                              <p className="my-5 rounded bg-darkBlue p-5 text-center text-white ">
                                Checkout temporarily disabled for maintenance.
                                Please try again later.
                              </p>
                            )}
                            <Button fullWidth disabled className="bg-darkGrey">
                              Checkout
                            </Button>
                          </>
                        ) : (
                          <Button fullWidth to={checkout?.webUrl}>
                            Checkout
                          </Button>
                        )}
                      </div>
                      <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                        <p>
                          or{" "}
                          <button
                            className="cursor-pointer font-medium text-darkBlue"
                            to="/products"
                            onClick={handleOnClose}
                          >
                            Continue Shopping
                            <span aria-hidden="true" onClick={handleOnClose}>
                              {" "}
                              &rarr;
                            </span>
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
