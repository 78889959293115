import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { setCart } from "../../../app/features/cartSlice"

import { useMenuItems } from "../../../hooks/useMenuItems"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import PromoBar from "./PromoBar"
import MobileMainNavigation from "./MobileMainNavigation"

import Search from "../../../components/Search"
import EditSVG from "../../../icons/EditSVG.svg"
import CartMenu from "../../cart/CartMenu"
import Cart from "../../../icons/Cart.svg"
import TopNavigation from "./TopNavigation"
import useParams from "../../../hooks/useParams"
import { useShopify } from "../../../shopify"
import useThemeOptions from "../../../hooks/useThemeOptions"

const MobileHeader = props => {
  const { commercialPage, simple, location, params: gatsbyParams } = props

  const dispatch = useDispatch()
  const params = useParams(location.search)

  const { checkout } = useShopify()

  const reducer = (accumulator, currentValue) => accumulator + currentValue
  const quantities =
    !!checkout?.lineItems && checkout?.lineItems.map(i => i.quantity)
  const cartItemCount = checkout ? quantities.reduce(reducer, 0) : 0

  const themeOptions = useThemeOptions()

  const menuItems = useMenuItems({
    location: commercialPage ? "COMMERCIAL_HEADER" : "GATSBY_HEADER_MENU",
  })

  const gatsbyImage =
    themeOptions?.Options?.header?.logo &&
    getImage(themeOptions?.Options?.header?.logo?.gatsbyImage)

  const [isOpen, setOpen] = useState(params?.menuisopen === "1" || false)

  const getScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth
  }

  const handleCart = () => {
    dispatch(setCart(true))
    const scrollbarWidth = getScrollbarWidth()
    document.body.style.overflow = "hidden"
    document.body.style.paddingRight = `${scrollbarWidth}px`
  }

  return (
    <>
      {commercialPage
        ? themeOptions?.Options?.commercialHeader?.promobar?.active &&
          !isOpen && (
            <PromoBar
              {...themeOptions?.Options?.commercialHeader?.promobar}
              commercialPage={commercialPage}
            />
          )
        : themeOptions?.Options?.header?.promobar?.active &&
          !isOpen && (
            <PromoBar
              {...themeOptions?.Options?.header?.promobar}
              commercialPage={commercialPage}
            />
          )}
      {!isOpen && <TopNavigation commercialPage={commercialPage} simple />}
      <header className="font-semibold uppercase">
        {!isOpen && (
          <>
            <div className="flex max-w-7xl justify-between text-secondaryNav ">
              {!isOpen &&
                (simple ? (
                  <a
                    target="_parent"
                    href={`${process.env.GATSBY_SITE_DOMAIN}/?menuisopen=1`}
                    className="my-auto ml-[5px] cursor-pointer p-5"
                  >
                    <div className="my-[4.25px] w-[35px] border-t-[3px] border-darkGrey"></div>
                    <div className="my-[4.25px] w-[35px] border-t-[3px] border-darkGrey"></div>
                    <div className="my-[4.25px] w-[35px] border-t-[3px] border-darkGrey"></div>
                  </a>
                ) : (
                  <button
                    onClick={() => setOpen(!isOpen)}
                    className="my-auto ml-[5px] cursor-pointer p-5"
                    aria-label="hamburger-menu"
                  >
                    <div className="my-[4.25px] w-[35px] border-t-[3px] border-darkGrey"></div>
                    <div className="my-[4.25px] w-[35px] border-t-[3px] border-darkGrey"></div>
                    <div className="my-[4.25px] w-[35px] border-t-[3px] border-darkGrey"></div>
                  </button>
                ))}

              {gatsbyImage && (
                <Link to="/" className="py-5">
                  <GatsbyImage
                    image={gatsbyImage}
                    alt={themeOptions?.Options?.header?.logo.altText || ""}
                  />
                </Link>
              )}
              {!commercialPage ? (
                simple ? (
                  <div className="flex items-center">
                    <a
                      target="_parent"
                      className="flex items-center space-x-1.5 p-5 uppercase"
                      href="/cart"
                    >
                      {cartItemCount <= 0 ? (
                        <Cart className="mr-2" />
                      ) : (
                        <div className="relative">
                          <div className="absolute top-[-5px] right-[2px] w-[50%] rounded-full border bg-darkBlue text-[#FFF]">
                            {cartItemCount}
                          </div>
                          <Cart className="mr-2" />
                        </div>
                      )}
                    </a>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <button
                      className="flex items-center space-x-1.5 p-5 uppercase"
                      onClick={handleCart}
                      aria-label="cart-menu"
                    >
                      {cartItemCount <= 0 ? (
                        <Cart className="mr-2" />
                      ) : (
                        <div className="relative">
                          <div className="absolute top-[-5px] right-[2px] w-[50%] rounded-full border bg-darkBlue text-[#FFF]">
                            {cartItemCount}
                          </div>
                          <Cart className="mr-2" />
                        </div>
                      )}
                    </button>
                  </div>
                )
              ) : (
                <div className="flex items-center">
                  <EditSVG />
                </div>
              )}
            </div>

            {!simple && <Search params={gatsbyParams} />}
          </>
        )}

        {isOpen && (
          <MobileMainNavigation
            simple
            menuItems={menuItems}
            setOpen={setOpen}
            commercialPage={commercialPage}
            isOpen={isOpen}
          />
        )}
      </header>
    </>
  )
}

export default MobileHeader
