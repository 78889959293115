import { graphql, useStaticQuery } from "gatsby"

interface UseMenuItemsProps {
  location?: string
  slug?: string
}

export const useMenuItems = ({ location, slug }: UseMenuItemsProps) => {
  // if (!location && !slug) {
  //   console.warn(`useMenuItems requires either a slug or location parameter`)
  //   console.warn(
  //     `useMenuItems example slug: "main-menu", example location: "GATSBY_HEADER_MENU"`
  //   )
  //   return null
  // }

  const {
    allWpMenu: { nodes },
  } = useStaticQuery(graphql`
    {
      allWpMenu {
        nodes {
          slug
          locations
          menuItems {
            nodes {
              menuDetails {
                description
                image {
                  altText
                  gatsbyImage(
                    width: 421
                    height: 421
                    fit: OUTSIDE
                    formats: [AUTO, WEBP]
                  )
                }
              }
              connectedNode {
                node {
                  uri
                }
              }
              url
              label
              target
              parentDatabaseId
              cssClasses
              childItems {
                nodes {
                  id
                  label
                  url
                  childItems {
                    nodes {
                      menuDetails {
                        description
                        image {
                          altText
                          gatsbyImage(
                            width: 421
                            height: 421
                            fit: OUTSIDE
                            formats: [AUTO, WEBP]
                          )
                        }
                      }
                      id
                      label
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  if (location) {
    const menu = nodes && nodes.find(node => node.locations.includes(location))

    const menuItems =
      menu?.menuItems?.nodes &&
      menu.menuItems.nodes.filter(item => item.parentDatabaseId === 0)

    return menuItems
  } else if (slug) {
    const menu = nodes.find(node => node.slug === slug)

    const menuItems =
      menu?.menuItems?.nodes &&
      menu.menuItems.nodes.filter(item => item.parentDatabaseId === 0)

    return menuItems
  }
}

export default useMenuItems
