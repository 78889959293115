import React, { useState } from "react"

import parse from "html-react-parser"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Button from "../../../button/Button"
import { useForm } from "react-hook-form"
import Recaptcha from "react-recaptcha"

interface INewsletterProps {
  description: string
  headline: string
  backgroundImage: any
}

export const Newsletter: React.FC<INewsletterProps> = ({
  headline,
  description,
  backgroundImage,
}) => {
  const bgImg =
    backgroundImage?.gatsbyImage && getImage(backgroundImage.gatsbyImage)
  const reCaptcha = process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY
  const [formSubmitted, setFormSubmit] = useState(false)
  // const acceptsMarketing = true

  const [loading, setLoading] = useState(false)

  let recaptchaInstance

  const { register, handleSubmit } = useForm()

  const onVerify = async response => {
    if (response) {
      setLoading(false)
    } else {
      return null
    }

    setLoading(false)
  }

  const signUpForNewsLetter = async data => {
    setLoading(true)

    if (reCaptcha) {
      // execute reCaptcha field
      recaptchaInstance.execute()
    } else {
      // else go straight to verfication function
      onVerify(true)
    }

    setFormSubmit(true)

    // const formData = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     email: data.email,
    //   }),
    // }

    // return await fetch("/api/signup-newsletter", formData)
  }
  const onError = () => console.log(`Valid Email is Required`)

  return (
    <div className=" relative py-[130px] ">
      <div className="z-1 absolute top-0 left-0 h-full w-full">
        {bgImg && (
          <GatsbyImage
            image={bgImg}
            alt={backgroundImage.altText || "newsletter-image"}
            className="z-1 absolute top-0 left-0 h-full w-full "
          />
        )}
        <div className="absolute left-0 top-0 h-full w-full bg-[linear-gradient(270deg,#000000_0%,#00000000_72.98%)]" />
        <div className="absolute left-0 top-0 h-full w-full bg-darkBlue bg-opacity-60 mix-blend-multiply" />
      </div>
      <div className="z-5 relative mx-auto flex max-w-[420px] flex-col items-center">
        <h2 className="mb-[20px] text-center text-white">{parse(headline)}</h2>

        <form
          className="flex flex-col items-center justify-center"
          onSubmit={handleSubmit(signUpForNewsLetter, onError)}
        >
          {!formSubmitted && (
            <>
              <input
                className="mb-[15px] min-h-[49px] w-full min-w-[175px] rounded-[4px] px-[20px] md:min-w-[412px]"
                placeholder="Your Email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                })}
              />

              {reCaptcha && (
                <Recaptcha
                  ref={e => (recaptchaInstance = e)}
                  sitekey={reCaptcha}
                  size="invisible"
                  verifyCallback={onVerify}
                />
              )}

              <Button
                loading={loading}
                type="submit"
                aria-label="submit newsletter"
                className="mt-[15px]"
              >
                Subscribe
              </Button>
            </>
          )}

          {formSubmitted && (
            <h2 className="text-[30px] text-yellow">Thanks for signing up!</h2>
          )}
        </form>

        <p className="text-subtTitle1 mt-[29px] text-[20px] font-semibold text-white">
          {description}
        </p>
      </div>
    </div>
  )
}

export default Newsletter
