import React from "react"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"

import { Script } from "gatsby"
// import app components
import ShopifyProvider from "../../shopify/index"
import Header from "./header/Header"
import MobileHeader from "./header/MobileHeader"
import Footer from "./footer/Footer"
import { store } from "../../app/store"
import { Provider } from "react-redux"
import useCustomer from "../../hooks/useCustomer"
import isBrowser from "../../utils/isBrowser"
import useThemeOptions from "../../hooks/useThemeOptions"
import CookieBar from "../CookieBar"

const Layout = props => {
  const options = useThemeOptions()
  const { location, children, path } = props
  const customer = useCustomer()

  const queryClient = new QueryClient()

  const disabledUrls = [
    `/simple-header/`,
    `/simple-footer/`,
    `/simple-newsletter/`,
    `/simple-header`,
    `/simple-footer`,
    `/simple-newsletter`,
  ]

  const isCommercialPage = path.includes("/commercial/")

  if (
    customer?.is_logged_in === "false" &&
    isBrowser &&
    props?.location?.pathname.includes("wishlist")
  ) {
    isBrowser &&
      (window.location.href = `https://${process.env.GATSBY_CUSTOM_SHOPIFY_DOMAIN}/account/login`)
    return null
  }

  if (disabledUrls?.includes?.(location.pathname)) {
    return <Provider store={store}>{children}</Provider>
  } else {
    return (
      <>
        <Script
          id="hs-script-loader"
          src="https://js-na1.hs-scripts.com/20968616.js"
          strategy="idle"
        />

        <QueryClientProvider client={queryClient}>
          <ShopifyProvider>
            <Provider store={store}>
              <div className="mb-[32px] hidden md:block">
                <Header commercialPage={isCommercialPage} {...props} />
              </div>

              <div className="block md:hidden">
                <MobileHeader
                  location={location}
                  commercialPage={isCommercialPage}
                  {...props}
                />
              </div>

              <main>{children}</main>

              <Footer commercialPage={isCommercialPage} {...props} />
            </Provider>
          </ShopifyProvider>
        </QueryClientProvider>

        <CookieBar content={options.cookieBanner} />
      </>
    )
  }
}

export default Layout
