const useParams = (search: string) => {
  const searchParams = new URLSearchParams(search)

  const params = {}

  for (const pair of searchParams.entries()) {
    // console.log(pair[0] + ": " + pair[1])
    params[pair[0]] = pair[1]
  }

  return params
}

export default useParams
