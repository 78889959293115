import { graphql, useStaticQuery } from "gatsby"

const useAllProducts = () => {
  const {
    allShopifyProduct: { nodes },
  } = useStaticQuery(graphql`
    {
      allShopifyProduct {
        nodes {
          ...ShopifyProductFields
          wpProduct {
            ...WpProductFields
          }
        }
      }
    }
  `)
  return nodes
}

export default useAllProducts
