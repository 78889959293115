import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"

import SearchIcon from "../icons/Search.svg"

const Search = ({ params }) => {
  const searchTerm = params?.term || ""

  const [value, setValue] = useState(searchTerm)

  const handleSubmit = e => {
    e.preventDefault()

    if (value) {
      navigate(`/search/${value}`)
    } else {
      navigate(`/products`)
    }
  }

  useEffect(() => {
    // if url updates (e.g. user clicks back button), update input value
    setValue(searchTerm)
  }, [searchTerm])

  return (
    <div>
      <div className="relative h-full w-full px-[18px] md:mr-5 md:w-72 md:px-0 lg:mr-7 xl:w-[340px] 2xl:w-[412px] ">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="h-[40px] w-full rounded border-none bg-lightGrey text-body2 font-medium"
            placeholder={"Product Search"}
            defaultValue={searchTerm}
            value={value}
            onChange={e => setValue(e.target.value)}
          />

          <button type="submit" aria-label="submit search">
            <SearchIcon className="absolute right-0 bottom-0 top-0 my-auto w-[20%] cursor-pointer" />
          </button>
        </form>
      </div>
    </div>
  )
}

export default Search
