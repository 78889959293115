import React from "react"

import { Script } from "gatsby"

export const wrapPageElement = ({ element, props }) => {
  const { location } = props
  const disabledUrls = [
    `/simple-header/`,
    `/simple-footer/`,
    `/simple-newsletter/`,
    `/simple-header`,
    `/simple-footer`,
    `/simple-newsletter`,
  ]

  return (
    <>
      {element}
      <noscript>
        <iframe
          title={`Google Tag Manager`}
          src="https://www.googletagmanager.com/ns.html?id=GTM-KLG7KVP"
          height="0"
          width="0"
          className="invisible hidden"
        ></iframe>
      </noscript>
      {!disabledUrls?.includes?.(location.pathname) && (
        <>
          <Script id="gtag-config" strategy="post-hydrate">
            {`
         
             (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-KLG7KVP');
           

          `}
          </Script>
        </>
      )}
    </>
  )
}
