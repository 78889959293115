import React from "react"
import { useMenuItems } from "../../../hooks/useMenuItems"
import { Link } from "gatsby"

const FooterNavigation = ({ commercialPage, simple }) => {
  const menuItems = useMenuItems({
    location: commercialPage ? "COMMERCIAL_FOOTER" : "GATSBY_FOOTER_MENU",
  })

  return (
    <footer className="bg-darkBlue pt-[91px] pb-[52px] text-white">
      <div className="mx-auto grid max-w-7xl grid-cols-2 justify-between gap-y-5 px-[15px] sm:px-6 md:grid-cols-4 md:pr-16 lg:pr-20 lg:pl-8">
        {menuItems.map(({ label, childItems }) => (
          <div className="px-[15px]" key={label}>
            <h5 className=" uppercase">{label}</h5>
            <div className="mt-4 space-y-3  text-body1">
              {childItems.nodes.map(({ label, url }, index) =>
                simple ? (
                  <a href={url} target="_parent" key={index}>
                    <div className="my-[15px] uppercase">{label}</div>
                  </a>
                ) : (
                  <Link to={url} key={index}>
                    <div className="my-[15px] uppercase">{label}</div>
                  </Link>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </footer>
  )
}

export default FooterNavigation
