import React, { createContext, useContext, useState, useEffect } from "react"
import Client from "shopify-buy/index.unoptimized.umd"

import { getLocalStorage, setLocalStorage } from "../utils"
import config from "./shopify-config"
// import useCheckoutProcess from "../utils/checkCartComplete"
import useLiveProducts from "./useLiveProducts"
import { getGoogleClientId } from "../utils/getGoogleClientId"
import isBrowser from "../utils/isBrowser"

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
  domain:
    process.env.GATSBY_CUSTOM_SHOPIFY_DOMAIN ||
    process.env.GATSBY_MYSHOPIFY_DOMAIN,
})

const ShopifyContext = createContext({})

export const ShopifyProvider = props => {
  const [clientData, setClientData] = useState(null)
  useEffect(() => {
    if (isBrowser && window?.ga) {
      const fetchData = async () => {
        const clientId = await getGoogleClientId()

        setClientData(clientId)
      }
      fetchData()
    }
  }, [isBrowser && window?.ga])

  const { checkoutId: existingCheckoutId } =
    getLocalStorage(config.storageKey) || {}

  const [checkout, setCheckout] = useState(null)
  // console.log({ checkout })
  const createNewCheckout = async client => {
    if (!client || !isBrowser) {
      return null
    }
    return await client.checkout
      .create()
      .then(checkout => {
        setLocalStorage(config.storageKey, { checkoutId: checkout.id })
        setCheckout(checkout)
        if (clientData !== null) {
          updateCheckoutCustomAttributes({
            overrideCheckoutId: checkout.id,
            customAttributes: [{ key: `google-clientID`, value: clientData }],
            onCompleted: completed => {
              console.log(completed)
            },
            onError: error => {
              console.log(error)
            },
          })
        }

        return checkout
      })
      .catch(err => {
        console.log({ err })

        // console.log(`Unable to reach store.`)
      })
  }

  // fetch checkout to see if it still exists or if it expired
  const getCheckout = async () => {
    if (!client || !isBrowser) {
      return null
    }

    return existingCheckoutId
      ? await client.checkout
          .fetch(existingCheckoutId)
          .then(async checkout => {
            if (checkoutValid?.completed) {
              // checkout already completed, create a new one instead
              document.cookie =
                "checkoutCompleted=false; path=/; domain=.fitnesstown.ca"

              return await createNewCheckout(client)
            }

            setLocalStorage(config.storageKey, { checkoutId: checkout.id })
            setCheckout(checkout)
            if (clientData !== null) {
              updateCheckoutCustomAttributes({
                overrideCheckoutId: checkout.id,
                customAttributes: [
                  { key: `google-clientID`, value: clientData },
                ],
                onCompleted: completed => {
                  console.log(completed)
                },
                onError: error => {
                  console.log(error)
                },
              })
            }
            return checkout
          })
          .catch(() => {
            // failed to fetch existing checkout -> create new checkout instead
            createNewCheckout(client)
          })
      : await createNewCheckout(client)
  }

  const addLineItem = ({
    notification,
    variant,
    quantity,
    onCompleted,
    onError,
  }) => {
    if (!isBrowser) {
      return null
    }
    if (!client) {
      console.warn("shopify client not found")
      return null
    }

    const handleErrorAddLineItem = (error?: any) => {
      getCheckout()

      console.log(
        `Something went wrong adding the item to cart. Please try again.`
      )

      onError && typeof onError === `function` && onError(error)
    }

    if (!checkout?.id) {
      handleErrorAddLineItem()
      return
    }

    const lineItemsToAdd = [
      {
        variantId: variant.shopifyId || variant.id,
        quantity,
      },
    ]
    client.checkout
      .addLineItems(checkout.id, lineItemsToAdd)
      .then(checkout => {
        setLocalStorage(config.storageKey, { checkoutId: checkout.id })
        setCheckout(checkout)

        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)
      })
      .catch(error => {
        // sometimes removing many items from cart too fast can cause an error -> refetch checkout if this happens
        handleErrorAddLineItem(error)
      })
  }

  const addLineItems = ({
    overrideCheckoutId,
    notification,
    lineItemsToAdd,
    onCompleted,
    onError,
  }: {
    notification?: any
    lineItemsToAdd: {
      variantId: string
      quantity: number
    }[]
    onCompleted?: (data?: any) => void
    onError?: (error?: any) => void
  }) => {
    if (!isBrowser) {
      return null
    }
    if (!client) {
      console.warn("shopify client not found")
      return null
    }

    const handleErrorAddLineItems = (error?: any) => {
      getCheckout()

      console.log(
        `Something went wrong adding the item(s) to cart. Please try again.`
      )

      onError && typeof onError === `function` && onError(error)
    }

    if (!checkout?.id) {
      handleErrorAddLineItems()
      return
    }

    client.checkout
      .addLineItems(overrideCheckoutId || checkout.id, lineItemsToAdd)
      .then(checkout => {
        setLocalStorage(config.storageKey, {
          checkoutId: overrideCheckoutId || checkout.id,
        })
        setCheckout(checkout)

        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)
      })
      .catch(error => {
        // sometimes removing many items from cart too fast can cause an error -> refetch checkout if this happens
        handleErrorAddLineItems(error)
      })
  }

  const removeLineItem = ({
    notification,
    lineItemId,
    onCompleted,
    onError,
  }) => {
    if (!isBrowser) {
      return null
    }

    if (!client) {
      console.warn("shopify client not found")
      return null
    }
    client.checkout
      .removeLineItems(checkout.id, [lineItemId])
      .then(checkout => {
        setLocalStorage(config.storageKey, { checkoutId: checkout.id })
        setCheckout(checkout)

        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)
      })
      .catch(error => {
        getCheckout()

        console.log(
          `Something went wrong removing the item from your cart. Please try again.`
        )

        onError && typeof onError === `function` && onError(error)
      })
  }

  const updateLineItem = ({
    notification,
    lineItemId,
    quantity,
    onCompleted,
    onError,
  }) => {
    if (!isBrowser) {
      return null
    }

    if (!client) {
      console.warn("shopify client not found")
      return null
    }

    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]

    client.checkout
      .updateLineItems(checkout.id, lineItemsToUpdate)
      .then(checkout => {
        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)

        setLocalStorage(config.storageKey, { checkoutId: checkout.id })
        setCheckout(checkout)
      })
      .catch(error => {
        getCheckout()

        console.log(
          `Something went wrong updating your cart. Please try again.`
        )

        onError && typeof onError === `function` && onError(error)
      })
  }

  const changeCurrencyCode = ({ newCurrencyCode, onCompleted, onError }) => {
    if (!client || !isBrowser) {
      return null
    }

    if (!newCurrencyCode) {
      console.warn("newCurrencyCode required for changeCurrencyCode")
      return null
    }

    let lineItems = []
    // create new array for checkout lineItemInput
    if (checkout?.lineItems && checkout?.lineItems?.length > 0) {
      lineItems = checkout?.lineItems.map(item => {
        return {
          variantId: item.variant.id,
          quantity: item.quantity,
        }
      })
    }

    client.checkout
      .create({
        presentmentCurrencyCode: newCurrencyCode,
        lineItems,
      })
      .then(checkout => {
        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)

        setLocalStorage(config.storageKey, { checkoutId: checkout.id })

        setCheckout(checkout)
      })
      .catch(error => {
        onError && typeof onError === `function` && onError(error)
      })
  }

  const updateCheckoutCustomAttributes = ({
    overrideCheckoutId,
    customAttributes,
    onCompleted,
    onError,
  }) => {
    if (!isBrowser) {
      return null
    }

    if (!checkout?.id && !overrideCheckoutId) {
      console.warn("updateCheckoutCustomAttributes: Checkout ID not found")
    }
    if (!client) {
      console.warn("updateCheckoutCustomAttributes: shopify client not found")
      return null
    }
    if (!customAttributes) {
      console.warn(
        "updateCheckoutCustomAttributes requires the customAttributes array to be provided"
      )
      return null
    }

    const input = {
      customAttributes,
    }

    client.checkout
      .updateAttributes(overrideCheckoutId || checkout.id, input)
      .then(checkout => {
        setLocalStorage(config.storageKey, {
          checkoutId: overrideCheckoutId || checkout.id,
        })
        setCheckout(checkout)

        onCompleted &&
          typeof onCompleted === `function` &&
          onCompleted(checkout)
      })
      .catch(error => {
        onError && typeof onError === `function` && onError(error)
      })
  }

  isBrowser && !checkout && getCheckout()

  const liveProducts = useLiveProducts()

  const getLiveProduct = shopifyId => {
    const liveProduct =
      liveProducts && liveProducts?.find?.(product => product.id === shopifyId)

    return liveProduct
  }

  const getLiveVariant = ({ shopifyId, variantId }) => {
    const liveProduct = getLiveProduct(shopifyId)

    const liveVariant = liveProduct?.variants?.edges?.find?.(
      ({ node }) => node.id === variantId
    )

    return liveVariant?.node
  }

  return (
    <ShopifyContext.Provider
      value={{
        client,
        checkout,
        addLineItem,
        addLineItems,
        removeLineItem,
        updateLineItem,
        changeCurrencyCode,
        updateCheckoutCustomAttributes,
        liveProducts,
        getLiveProduct,
        getLiveVariant,
        createNewCheckout,
      }}
      {...props}
    />
  )
}

export const useShopify = () => useContext(ShopifyContext)

export default ShopifyProvider
