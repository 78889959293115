import React, { useState } from "react"

import { useDispatch } from "react-redux"
import { setCart } from "../../../app/features/cartSlice"
import { useMenuItems } from "../../../hooks/useMenuItems"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import PromoBar from "./PromoBar"
import MainNavigation from "./MainNavigation"
import TopNavigation from "./TopNavigation"
import CartMenu from "../../cart/CartMenu"
import Button from "../../button/Button"
import Search from "../../../components/Search"
import User from "../../../icons/User.svg"
import Cart from "../../../icons/Cart.svg"

import SubNav from "./SubNav"
import { useShopify } from "../../../shopify"
import useThemeOptions from "../../../hooks/useThemeOptions"
import useCustomer from "../../../hooks/useCustomer"

const Header = props => {
  const { commercialPage, uri, simple, location, params } = props

  const themeOptions = useThemeOptions()
  const customer = useCustomer()
  const { checkout } = useShopify()

  const reducer = (accumulator, currentValue) => accumulator + currentValue
  const quantities =
    !!checkout?.lineItems && checkout?.lineItems.map(i => i.quantity)
  const cartItemCount = checkout ? quantities.reduce(reducer, 0) : 0

  const dispatch = useDispatch()
  const menuItems = useMenuItems({
    location: commercialPage ? "COMMERCIAL_HEADER" : "GATSBY_HEADER_MENU",
  })
  const [accountMenu, setAccountMenu] = useState(false)
  const gatsbyImage =
    themeOptions?.Options?.header?.logo &&
    getImage(themeOptions?.Options?.header?.logo?.gatsbyImage)

  const getScrollbarWidth = () => {
    return window.innerWidth - document.documentElement.clientWidth
  }

  const handleCart = () => {
    dispatch(setCart(true))
    const scrollbarWidth = getScrollbarWidth()
    document.body.style.overflow = "hidden"
    document.body.style.paddingRight = `${scrollbarWidth}px`
  }

  const accountMenuItems = [
    {
      label: "My Orders",
      url: `http${process.env.GATSBY_MYSHOPIFY_DEV ? `` : `s`}://${
        process.env.GATSBY_MYSHOPIFY_DEV ||
        process.env.GATSBY_CUSTOM_SHOPIFY_DOMAIN
      }/account`,
    },
    { label: "Wishlist", url: "/wishlist" },
    {
      label: "Logout",
      url: "/logout",
    },
  ]
  return (
    <>
      {commercialPage
        ? themeOptions?.Options?.commercialHeader?.promobar?.active && (
            <PromoBar
              {...themeOptions?.Options?.commercialHeader?.promobar}
              commercialPage={commercialPage}
              simple={simple}
            />
          )
        : themeOptions?.Options?.header?.promobar?.active && (
            <PromoBar
              {...themeOptions?.Options?.header?.promobar}
              commercialPage={commercialPage}
              simple={simple}
            />
          )}

      <header className="font-semibold uppercase shadow-header">
        <TopNavigation commercialPage={commercialPage} simple={simple} />

        <div className="mx-auto flex max-w-7xl items-center justify-between px-[15px] py-2 text-secondaryNav">
          {gatsbyImage &&
            (!simple ? (
              <Link
                to={
                  commercialPage
                    ? themeOptions?.Options?.commercialHeader?.logoLink
                    : "/"
                }
                aria-label="back-to-home-logo"
                title="back-to-home-logo"
              >
                <GatsbyImage
                  image={gatsbyImage}
                  alt={themeOptions?.Options?.header?.logo.altText || ""}
                />
              </Link>
            ) : (
              <a
                target="_parent"
                href="/"
                aria-label="back-to-home-logo"
                title="back-to-home-logo"
              >
                <GatsbyImage
                  image={gatsbyImage}
                  alt={themeOptions?.Options?.header?.logo.altText || ""}
                />
              </a>
            ))}

          {!commercialPage ? (
            <div className="flex items-center">
              {!simple && <Search params={params} />}

              <div
                role="button"
                tabIndex={0}
                className="relative"
                onMouseEnter={() => setAccountMenu(true)}
                onMouseLeave={() => setAccountMenu(false)}
              >
                {simple ? (
                  <a
                    target="_parent"
                    href={`http${
                      process.env.GATSBY_MYSHOPIFY_DEV ? `` : `s`
                    }://${
                      process.env.GATSBY_MYSHOPIFY_DEV ||
                      process.env.GATSBY_CUSTOM_SHOPIFY_DOMAIN
                    }/account`}
                    className="flex items-center space-x-1.5  pr-2 font-semibold uppercase "
                  >
                    <User className="mr-2" />
                    ACCOUNT
                  </a>
                ) : (
                  <a
                    href={`http${
                      process.env.GATSBY_MYSHOPIFY_DEV ? `` : `s`
                    }://${
                      process.env.GATSBY_MYSHOPIFY_DEV ||
                      process.env.GATSBY_CUSTOM_SHOPIFY_DOMAIN
                    }/account`}
                    className="flex items-center space-x-1.5  pr-2 font-semibold uppercase "
                  >
                    <User className="mr-2" />
                    ACCOUNT
                  </a>
                )}

                <div className="z-4 absolute right-[-15px] h-[13px] w-[128px]"></div>
                {accountMenu && customer?.is_logged_in === "true" && (
                  <div
                    role="button"
                    tabIndex={0}
                    className="relative right-2 bottom-5"
                    onClick={() => setAccountMenu(false)}
                    onKeyDown={() => setAccountMenu(false)}
                  >
                    <SubNav childItems={accountMenuItems} simple />
                  </div>
                )}
              </div>
              {simple ? (
                <a
                  target="_parent"
                  href="/cart"
                  className="flex h-full cursor-pointer items-center space-x-1.5 pl-3 font-semibold uppercase"
                >
                  {cartItemCount <= 0 ? (
                    <Cart className="mr-2" />
                  ) : (
                    <div className="relative">
                      <div className="bo absolute top-[-5px] right-[2px] w-[50%] rounded-full border bg-darkBlue text-[#FFF]">
                        {cartItemCount}
                      </div>
                      <Cart className="mr-2" />
                    </div>
                  )}
                  CART
                </a>
              ) : (
                <button
                  className="flex cursor-pointer items-center space-x-1.5  pl-3 font-semibold uppercase"
                  onClick={handleCart}
                >
                  {cartItemCount <= 0 ? (
                    <Cart className="mr-2" />
                  ) : (
                    <div className="relative">
                      <div className="absolute top-[-5px] right-[2px] w-[50%] rounded-full border bg-darkBlue text-[#FFF]">
                        {cartItemCount}
                      </div>
                      <Cart className="mr-2" />
                    </div>
                  )}
                  CART
                </button>
              )}
            </div>
          ) : (
            <Button
              className="mt-[5px] h-[40px]"
              size="medium"
              to={themeOptions?.Options?.commercialHeader?.url}
            >
              {themeOptions?.Options?.commercialHeader?.requestAQuote?.title ||
                "Request a Quote"}
            </Button>
          )}
        </div>

        <MainNavigation
          simple={simple}
          pageUrl={uri}
          menuItems={menuItems}
          commercialPage={commercialPage}
        />
      </header>

      {!commercialPage && <CartMenu location={location} />}
    </>
  )
}

export default Header
