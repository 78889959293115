import { useInfiniteQuery } from "@tanstack/react-query"
import { request, gql } from "graphql-request"

const useLiveProducts = () => {
  const endpoint = `https://${process.env.GATSBY_MYSHOPIFY_DOMAIN}/api/2022-10/graphql.json`

  const productQuery = gql`
    query productsQuery($first: Int!, $after: String) {
      products(first: $first, after: $after) {
        edges {
          cursor
          node {
            id
            title
            availableForSale
            featuredImage {
              altText
              height
              id
              url
              width
            }
            handle
            variants(first: 100) {
              edges {
                node {
                  id
                  title
                  availableForSale
                  price {
                    amount
                    currencyCode
                  }
                  compareAtPrice {
                    amount
                    currencyCode
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const { data, isLoading, fetchNextPage } = useInfiniteQuery({
    queryKey: ["liveProducts"],
    queryFn: async ({ pageParam = undefined }) =>
      request(
        endpoint,
        productQuery,
        {
          first: 200,
          after: pageParam,
        },
        {
          "X-Shopify-Storefront-Access-Token":
            process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
        }
      ),

    getNextPageParam: (lastPage, pages) => {
      // get next page param from last item's cursor
      return lastPage?.products?.edges?.at(-1)?.cursor || undefined
    },
  })

  const newestItem = data?.pages.at(-1)?.products?.edges?.at(-1)

  if (newestItem?.cursor && !isLoading) {
    // get next page if most recent product has a cursor
    fetchNextPage()
  }

  const allProducts = data?.pages
    .reduce((acc, page) => {
      return [...acc, ...page.products.edges]
    }, [])
    .map(edge => edge.node)

  return allProducts
}

export default useLiveProducts
