import React, { useState } from "react"
import CustomLink from "../../CustomLink"
import { useMenuItems } from "../../../hooks/useMenuItems"
import ArrowRightSmall from "../../../icons/ArrowRightSmall.svg"
import Arrow from "../../../icons/arrow.svg"
import CanadianLeaf from "../../../icons/CanadianLeaf.svg"
import SubNav from "./SubNav"

const TopNavigation = ({ commercialPage, simple }) => {
  const menuItems = useMenuItems({
    location: commercialPage ? "COMMERCIAL_TOP_NAVIGATION" : "TOP_NAVIGATION",
  })
  const [toggleSubNav, setToggleSubNav] = useState(null)

  const openSubNav = e => {
    setToggleSubNav(e.target.id)
  }
  return (
    <div className="bg-darkBlue">
      <nav className="mx-auto flex h-[47px] max-w-7xl justify-between px-[0px] font-sans text-body1 font-normal text-white md:h-10 md:px-[15px] md:text-secondaryNav md:font-bold">
        <div className="flex w-full md:w-fit">
          {menuItems &&
            menuItems.map((item, index) => {
              return index <= 1 && simple ? (
                <a
                  target="_parent"
                  key={index}
                  className={
                    index === 0
                      ? "flex w-full cursor-pointer items-center bg-lightGrey px-[19px] text-darkBlue md:w-fit md:bg-white"
                      : "flex hidden w-full cursor-pointer items-center space-x-2 bg-mediumBlue px-[19px] md:w-fit md:bg-darkBlue"
                  }
                  href={item.url}
                >
                  {item.label}
                  {index === 1 && (
                    <div className="ml-auto flex flex-row justify-between">
                      <ArrowRightSmall
                        className="mt-[1px] hidden md:ml-[8px] md:block"
                        fill="#fff"
                      />
                      <Arrow className="relative block h-[12px] w-[9px] rotate-180 md:hidden" />
                    </div>
                  )}
                </a>
              ) : (
                index <= 1 && (
                  <CustomLink
                    key={index}
                    className={
                      index === 0
                        ? "flex w-full cursor-pointer items-center bg-lightGrey px-[19px] text-darkBlue md:w-fit md:bg-white"
                        : "flex hidden w-full cursor-pointer items-center space-x-2 bg-mediumBlue px-[19px] md:w-fit md:bg-darkBlue"
                    }
                    to={item.url}
                  >
                    {item.label}
                    {index === 1 && (
                      <div className="ml-auto flex flex-row justify-between">
                        <ArrowRightSmall
                          className="mt-[1px] hidden md:ml-[8px] md:block"
                          fill="#fff"
                        />
                        <Arrow className="relative block h-[12px] w-[9px] rotate-180 md:hidden" />
                      </div>
                    )}
                  </CustomLink>
                )
              )
            })}
        </div>
        <div className="z-1 hidden items-center space-x-[15px] divide-x-2 divide-white md:flex">
          <CanadianLeaf />

          {menuItems &&
            menuItems.map((item, index) => {
              return index >= 2 && simple ? (
                <div key={index}>
                  {item?.childItems?.nodes?.length === 0 && (
                    <a
                      target="_parent"
                      className={
                        index === 0
                          ? "flex cursor-pointer items-center bg-white pl-[19px] text-darkBlue"
                          : "flex cursor-pointer items-center space-x-2 pl-[19px]"
                      }
                      href={item.url}
                    >
                      {item.label}
                    </a>
                  )}
                  {item?.childItems?.nodes?.length > 0 && (
                    <button
                      id={item.label}
                      className={
                        toggleSubNav === item.label
                          ? "z-4 relative cursor-pointer pl-[15px] text-yellow"
                          : "z-4 relative cursor-pointer pl-[15px]"
                      }
                    >
                      {item.label}
                      <div
                        id={item.label}
                        className="z-4 absolute right-[-15px] h-[13px] w-[128px]"
                      ></div>
                    </button>
                  )}
                </div>
              ) : (
                index >= 2 && (
                  <div key={index}>
                    {item?.childItems?.nodes?.length === 0 && (
                      <CustomLink
                        className={
                          index === 0
                            ? "flex cursor-pointer items-center bg-white pl-[19px] text-darkBlue"
                            : "flex cursor-pointer items-center space-x-2 pl-[19px]"
                        }
                        to={item.url}
                      >
                        {item.label}
                      </CustomLink>
                    )}
                    {item?.childItems?.nodes?.length > 0 && (
                      <button
                        onMouseLeave={() => setToggleSubNav(null)}
                        onMouseEnter={e => openSubNav(e)}
                        id={item.label}
                        className={
                          toggleSubNav === item.label
                            ? "z-4 relative cursor-pointer pl-[15px] text-yellow"
                            : "z-4 relative cursor-pointer pl-[15px]"
                        }
                      >
                        {item.label}
                        <div
                          id={item.label}
                          className="z-4 absolute right-[-15px] h-[13px] w-[128px]"
                        ></div>
                        {toggleSubNav === item.label && (
                          <SubNav childItems={item.childItems.nodes} />
                        )}
                      </button>
                    )}
                  </div>
                )
              )
            })}
        </div>
      </nav>
    </div>
  )
}

export default TopNavigation
