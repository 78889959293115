import React, { useState } from "react"
import { Link } from "gatsby"

import Arrow from "../../../icons/arrow.svg"
import TopNavigation from "./TopNavigation"
import { useLockBodyScroll } from "../../../hooks/useLockBodyScroll"
import classNames from "classnames"
import { useMenuItems } from "../../../hooks/useMenuItems"
import XMenuButton from "../../../icons/XMenuButton.svg"
import useCustomer from "../../../hooks/useCustomer"

interface IMobileMainNavigation {
  commercialPage: boolean
  simple: any
  menuItems: [
    {
      label: string
      url: string
      childItems: any
    }
  ]
  setOpen: any
  isOpen: any
}

const accountMenuItems = [
  {
    label: "My Orders",
    url: `http${process.env.GATSBY_MYSHOPIFY_DEV ? `` : `s`}://${
      process.env.GATSBY_MYSHOPIFY_DEV || process.env.GATSBY_MYSHOPIFY_DOMAIN
    }/account`,
  },
  { label: "Wishlist", url: "/wishlist" },
  {
    label: "Logout",
    url: "/logout",
  },
]

const MobileMainNavigation = ({
  menuItems,
  simple,
  isOpen,
  setOpen,
  commercialPage,
}: IMobileMainNavigation) => {
  const topNavigationMenuItems = useMenuItems({
    location: commercialPage ? "COMMERCIAL_TOP_NAVIGATION" : "TOP_NAVIGATION",
  })

  const [selectedMenu, setSelectedMenu] = useState("default")
  const [selectedSubMenu, setSelectedSubMenu] = useState("default")

  const toggleMenuItems = menuTitle => {
    setSelectedSubMenu("default")
    menuTitle !== selectedMenu
      ? setSelectedMenu(menuTitle)
      : setSelectedMenu("default")
  }
  const toggleSubMenuItems = subMenuTitle => {
    subMenuTitle !== selectedSubMenu
      ? setSelectedSubMenu(subMenuTitle)
      : setSelectedSubMenu("default")
  }
  useLockBodyScroll()

  const customer = useCustomer()

  return (
    <nav className="fixed top-0 left-0 right-0 z-40 h-screen overflow-y-auto border-darkBlue bg-white">
      <div className="flex h-[55px] w-full items-center bg-darkBlue">
        <div className="pl-[18px] text-body1 text-[15px] font-normal text-white">
          Menu
        </div>
        <div className="my-auto ml-auto mr-[15px] cursor-pointer">
          <button onClick={() => setOpen(!isOpen)}>
            <XMenuButton />
          </button>
        </div>
      </div>
      {isOpen && <TopNavigation commercialPage={commercialPage} simple />}
      <div className="mx-auto flex cursor-pointer flex-col justify-start px-[20px] pt-[13px] pt-[3px] font-sans text-[14px] font-semibold uppercase text-darkGrey ">
        {menuItems.map(({ label, url, childItems }, index) => (
          <div>
            <button
              key={index}
              title={label}
              onClick={e => toggleMenuItems(e.target.title)}
              className="flex w-full flex-row items-center justify-between font-semibold uppercase"
            >
              <div className="my-[8px]" title={label}>
                {label}
              </div>
              {!commercialPage && (
                <Arrow
                  className={classNames(
                    "pointer-events-none z-10  ml-auto mr-[-6px] h-[12px] w-[14px] w-[9px] -translate-x-1 text-inherit transition-transform",
                    selectedMenu === label ? "-rotate-90" : "-rotate-180"
                  )}
                />
              )}
            </button>
            {!commercialPage && selectedMenu === label && (
              <>
                {childItems.nodes.map(items => {
                  return (
                    <div className="flex flex-row items-center">
                      <button
                        className="flex w-full flex-col font-semibold uppercase"
                        onClick={e => toggleSubMenuItems(e.target.title)}
                      >
                        <div
                          className="flex w-full flex-row items-center"
                          title={items.label}
                        >
                          <div
                            className="my-[8px] ml-[18px]"
                            title={items.label}
                          >
                            {items.label}
                          </div>

                          <Arrow
                            title={items.label}
                            className={classNames(
                              "pointer-events-none z-10  ml-auto mr-[-6px] h-[12px] w-[14px] w-[9px] -translate-x-1 text-inherit transition-transform",
                              selectedSubMenu === items.label
                                ? "-rotate-90"
                                : "-rotate-180"
                            )}
                          />
                        </div>
                        {selectedSubMenu === items.label &&
                          items?.childItems?.nodes?.map(itemChildItems => {
                            return simple ? (
                              <a
                                target="_parent"
                                href={itemChildItems.url}
                                className="flex w-full flex-row items-center"
                                onClick={() => setOpen(!isOpen)}
                              >
                                <div
                                  className="flex flex-row items-center"
                                  title={itemChildItems.label}
                                >
                                  <div
                                    className="my-[3px] ml-[36px] text-[13px] font-semibold normal-case"
                                    title={itemChildItems.label}
                                  >
                                    {itemChildItems.label}
                                  </div>
                                </div>
                              </a>
                            ) : (
                              <Link
                                to={itemChildItems.url}
                                className="flex flex-row items-center"
                                onClick={() => setOpen(!isOpen)}
                              >
                                <div
                                  className="flex flex-row items-center"
                                  w-full
                                  title={itemChildItems.label}
                                >
                                  <div
                                    className="my-[3px] ml-[36px] text-[13px] font-semibold normal-case"
                                    title={itemChildItems.label}
                                  >
                                    {itemChildItems.label}
                                  </div>
                                </div>
                              </Link>
                            )
                          })}
                      </button>
                    </div>
                  )
                })}
              </>
            )}
          </div>
        ))}
      </div>
      <div className="flex cursor-pointer flex-col bg-lightGrey px-[20px] pt-[3px] pb-[30px]">
        {topNavigationMenuItems &&
          topNavigationMenuItems.map((item, index) => {
            return (
              index >= 2 && (
                <>
                  <Link
                    className="my-[8px] flex flex-row items-center text-[14px] font-semibold text-darkGrey"
                    key={index}
                    to={item.url}
                    title={item.label}
                    onClick={e => {
                      toggleMenuItems(e.target.title)
                      item?.childItems?.nodes?.length < 1 && setOpen(!isOpen)
                    }}
                  >
                    {item?.label}
                    {item?.childItems?.nodes?.length > 0 && (
                      <Arrow
                        className={classNames(
                          "pointer-events-none z-10  ml-auto mr-[-6px] h-[12px] w-[14px] w-[9px] -translate-x-1 text-inherit transition-transform",
                          selectedMenu === item.label
                            ? "-rotate-90"
                            : "-rotate-180"
                        )}
                      />
                    )}
                  </Link>

                  {!commercialPage &&
                    selectedMenu === item.label &&
                    item.childItems.nodes.map(items => {
                      return (
                        <div className="flex flex-row items-center text-[13px] capitalize">
                          <button
                            className="flex w-full flex-col font-semibold"
                            onClick={e => toggleSubMenuItems(e.target.title)}
                          >
                            <Link
                              to={items.url}
                              className="my-[4px] ml-[18px]"
                              title={items.label}
                              onClick={() => setOpen(!isOpen)}
                            >
                              {items.label}
                            </Link>
                          </button>
                        </div>
                      )
                    })}
                </>
              )
            )
          })}
        <a
          href={`http${process.env.GATSBY_MYSHOPIFY_DEV ? `` : `s`}://${
            process.env.GATSBY_MYSHOPIFY_DEV ||
            process.env.GATSBY_MYSHOPIFY_DOMAIN
          }/account`}
          className="my-[8px] flex flex-row items-center text-[14px] font-semibold text-darkGrey"
        >
          My Account
        </a>

        {customer?.is_logged_in === "true" &&
          accountMenuItems?.map?.((item, index) => {
            return (
              <a
                className="my-[8px] flex flex-row items-center text-[14px] font-semibold text-darkGrey"
                key={index}
                href={item.url}
                title={item.label}
                onClick={() => setOpen(!isOpen)}
              >
                {item.label}
              </a>
            )
          })}
      </div>
    </nav>
  )
}
export default MobileMainNavigation
