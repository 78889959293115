import React from "react"
import { Link } from "gatsby"

const SubNav = ({ childItems, simple }) => {
  return (
    <div className="absolute left-[-15px] z-50 mt-[12px] min-w-[161px]">
      {/* arrow up */}

      <div className="absolute top-[5px] left-1/2 z-0 h-7 w-7 -translate-x-1/2 -translate-y-2.5 rotate-45 transform rounded-sm bg-white shadow-menu"></div>
      <div className="absolute top-[5px] left-1/2 z-10 h-7 w-7 -translate-x-1/2 -translate-y-2.5 rotate-45 transform rounded-sm bg-white"></div>

      {/* menu */}
      <div className="absolute z-20 flex w-full flex-col items-center rounded bg-white pb-3 normal-case text-darkGrey shadow-menu">
        {childItems?.map((item, index) => {
          if (item.onClick) {
            return (
              <div key={index} className="text-center">
                <div className="mt-[2px] cursor-pointer text-[13px] leading-[29px] hover:text-yellow">
                  <button onClick={item.onClick}>{item.label}</button>
                </div>
              </div>
            )
          }

          return (
            <div key={index} className="text-center">
              {item?.url === "#" ? (
                <h6 className="mx-auto w-fit cursor-default border-b-2 border-lightGrey pt-[30px] pb-[7px]">
                  {item.label}
                </h6>
              ) : (
                <div className="mt-[2px] cursor-pointer text-[13px] font-normal leading-[29px]  text-darkGrey hover:text-yellow">
                  {simple ? (
                    <a target="_parent" href={item.url}>
                      {item.label}
                    </a>
                  ) : (
                    <Link to={item.url + `${"#" + item.label}`}>
                      {item.label}
                    </Link>
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SubNav
