import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

interface IButtonProps {
  /**
   * The button variant. Changes the colors.
   */
  variant: "primary" | "secondary"
  /**
   * The size of the button.
   */
  size: "large" | "medium" | "small"
  /**
   * The url for the button to navigate to
   */
  to: string
  /**
   * Sets the width to 100%
   */
  fullWidth: boolean
  /**
   * Disables the button
   */
  disabled: boolean
  /**
   * Displays a loading spinner and disables the button
   */
  loading: boolean

  /**
   * className to be combined with the other classNames
   */
  className: string

  children?: any

  onClick?: () => void
}

export const Button: React.FC<Partial<IButtonProps>> = ({
  variant = "primary",
  size = "large",
  fullWidth = false,
  children,
  to = "",
  loading = false,
  disabled = false,
  className = "",
  ...rest
}) => {
  const combinedClassNames = classNames(
    "mx-3", // offset horizontal margin to compensate for the skew overflowing
    "relative",
    "font-neusa font-bold tracking-wider text-[17px] leading-snug",
    "inline-block align-middle text-center uppercase",
    "skew-x-[-20deg]",

    variant === "primary"
      ? "bg-yellow text-darkBlue"
      : "bg-mediumBlue text-white",

    size === "large" && "py-[10px] px-[83px]",
    size === "medium" && "py-[5px] px-[50px]",
    size === "small" && "py-[5px] px-[20px]",

    fullWidth && "w-full",

    (disabled || loading) && "bg-lightGrey text-mediumGrey cursor-not-allowed",

    className
  )

  if (to?.startsWith?.("http")) {
    return (
      <a className={combinedClassNames} href={to} {...rest}>
        <ChildWrapper loading={loading}>{children}</ChildWrapper>
      </a>
    )
  } else if (to) {
    return (
      <Link className={combinedClassNames} to={to} {...rest}>
        <ChildWrapper loading={loading}>{children}</ChildWrapper>
      </Link>
    )
  } else {
    return (
      <button
        className={combinedClassNames}
        disabled={loading || disabled}
        {...rest}
      >
        <ChildWrapper loading={loading} variant={variant}>
          {children}
        </ChildWrapper>
      </button>
    )
  }
}

const ChildWrapper = ({ loading, children, variant }) => {
  return (
    <>
      <Spinner
        loading={loading}
        svgColor={variant === "secondary" && "text-white"}
      />

      <span
        className={classNames(
          "mt-[5px]",
          "text-button",
          "inline-block skew-x-[+20deg]",
          loading && "opacity-0"
        )}
      >
        {children}
      </span>
    </>
  )
}

const Spinner = ({ loading, svgColor }) => {
  return (
    <div
      className={classNames(
        "absolute inset-0 flex skew-x-[+20deg] items-center justify-center",
        "opacity-0",
        loading && "opacity-100"
      )}
    >
      <svg
        className={classNames(
          "h-6 w-6 animate-spin ",
          svgColor ? svgColor : `text-darkBlue`
        )}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  )
}

export default Button
