import React from "react"
import { Link } from "gatsby"
import Truck from "../../../icons/Truck.svg"
import MobileTruck from "../../../icons/MobileTruck.svg"
import Parser from "html-react-parser"

interface IPromoBar {
  commercialPage: boolean

  active: boolean
  link: {
    target: string
    title: string
    url: string
  }
  stickyBar: boolean
  simple: boolean
}

const PromoBar = ({ link, stickyBar, commercialPage, simple }: IPromoBar) => {
  return (
    <div
      className={
        stickyBar
          ? "sticky top-0 z-[100] flex h-[55px] items-center justify-center space-x-2.5 bg-darkBlue py-[7px] px-5 md:h-fit md:bg-lightGrey"
          : "flex h-[55px] items-center justify-center space-x-2.5 bg-darkBlue py-[7px] px-5 md:h-fit md:bg-lightGrey"
      }
    >
      {!commercialPage && (
        <MobileTruck className="my-auto block flex-shrink-0 fill-white md:hidden" />
      )}
      {!commercialPage && (
        <Truck className="hidden flex-shrink-0 fill-white md:block" />
      )}
      {link && simple && (
        <a target="_parent" href={link.url}>
          <p className="font-sans text-secondaryNav font-semibold text-white md:text-darkBlue">
            {link?.title && Parser(link?.title)}
          </p>
        </a>
      )}
      {link && !simple && (
        <Link to={link.url}>
          <p className="font-sans text-secondaryNav font-semibold text-white md:text-darkBlue">
            {link?.title && Parser(link?.title)}
          </p>
        </Link>
      )}
    </div>
  )
}

export default PromoBar
