import React from "react"
import parse from "html-react-parser"
import styled from "@emotion/styled"

const Wysiwyg = props => {
  const { content } = props

  let contentString
  if (typeof content === "string" || content instanceof String) {
    contentString = content.toString().replace("data-srcset", "srcset")

    return (
      <Container className="wysiwyg relative z-10 font-sans md:inline-block ">
        {parse(contentString)}
      </Container>
    )
  } else {
    return null
  }
}

export default Wysiwyg

const Container = styled.div`
  img {
    max-width: 500px;
    width: 100%;
    height: auto;
    margin-right: auto !important;
    margin-left: auto !important;
    @media only screen and (min-width: 600px) {
      &.aligncenter {
        margin-right: auto !important;
        margin-left: auto !important;
      }
      &.alignright {
        width: 100%;
        float: right;
        margin-left: 20px !important;
      }
      &.alignleft {
        float: left;
        margin-right: 20px !important;
      }
      &.size-medium {
        width: 300px;
      }
      &.size-large {
        width: 600px;
      }
      &.size-thumbnail {
        width: 150px;
      }
    }
  }
  .gatsby-image-wrapper {
    width: fit-content;
    height: auto;

    @media only screen and (min-width: 600px) {
      &.aligncenter {
        margin-right: auto !important;
        margin-left: auto !important;
      }
      &.alignright {
        width: 100%;
        float: right;
        margin-left: 20px !important;
      }
      &.alignleft {
        float: left;
        margin-right: 20px !important;
      }
      &.size-medium {
        width: 300px;
      }
      &.size-large {
        width: 600px;
      }
      &.size-thumbnail {
        width: 150px;
      }
    }
  }
  p > span {
    margin-bottom: 5px;
  }
`
