import React from "react"
import { Link } from "gatsby"

const CustomLink = ({ to, children, className }) => {
  return to.includes("tel") ? (
    <a className={className} href={to}>
      {children}
    </a>
  ) : (
    <Link className={className} to={to}>
      {children}
    </Link>
  )
}

export default CustomLink
