import React, { useState } from "react"
import { Link } from "gatsby"
import DropdownNav from "../../dropdownNav/DropdownNav"

interface IMainNavigation {
  commercialPage: boolean
  simple: boolean
  menuItems: [
    {
      label: string
      url: string
      childItems: any
    }
  ]
  pageUrl: string
}

const MainNavigation = ({ menuItems, simple, pageUrl }: IMainNavigation) => {
  const [hoveredMenu, setHoveredMenu] = useState("default")
  const [hoveredCollection, setHoveredCollection] = useState("default")

  const resetMenu = () => {
    setHoveredMenu("default")
    setHoveredCollection("default")
  }

  const setMenuItemByUrl = (e, childItems) => {
    setHoveredMenu(e)
    const checkUrl = childItems.nodes.find(item => {
      return item.url === pageUrl
    })

    checkUrl
      ? setHoveredCollection(checkUrl.label)
      : setHoveredCollection("default")
  }

  return simple ? (
    <div className="w-full">
      <nav className="h-11 cursor-default border-t-2 border-darkBlue ">
        <div className="mx-auto flex  h-full max-w-7xl cursor-default items-center  px-[15px] pt-[3px] font-neusa text-primaryNav font-bold uppercase text-darkBlue md:space-x-[30px] lg:space-x-[78px] ">
          {menuItems.map(({ label, url }, index) => (
            <a target="_parent" key={index} href={url} title={label}>
              {label}
            </a>
          ))}
        </div>
      </nav>
    </div>
  ) : (
    <div
      role="presentation"
      onMouseLeave={e => setHoveredMenu("default")}
      className="w-full"
    >
      <nav className="h-11 cursor-default border-t-2 border-darkBlue">
        <div className="mx-auto flex  h-full max-w-7xl cursor-default items-center  px-[15px] pt-[3px] font-neusa text-primaryNav font-bold uppercase text-darkBlue md:space-x-[30px] lg:space-x-[78px] ">
          {menuItems.map(({ label, url, childItems }, index) => (
            <Link
              key={index}
              to={url}
              onMouseOver={e => setMenuItemByUrl(e.target.title, childItems)}
              onClick={resetMenu}
              className={
                hoveredMenu === label
                  ? "border-b-[6px] border-mediumBlue pt-[12px] pb-[6px]"
                  : ""
              }
              title={label}
            >
              {label}
            </Link>
          ))}
        </div>
      </nav>

      <DropdownNav
        pageUrl={pageUrl}
        setHoveredMenu={setHoveredMenu}
        menuItems={menuItems}
        hoveredMenu={hoveredMenu}
        hoveredCollection={hoveredCollection}
        setHoveredCollection={setHoveredCollection}
        resetMenu={resetMenu}
      />
    </div>
  )
}

export default MainNavigation
