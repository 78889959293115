import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  active: false,
}

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    toggleCart: state => {
      state.active = !state.active
    },
    setCart: (state, action) => {
      state.active = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleCart, setCart } = cartSlice.actions

export default cartSlice.reducer
